var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { sendDataToDataLayer } from '@bjs/shared-micro-frontend';
import { environmentConst } from '../config/environments/constants';
import { parseCookie } from './helper';
var getItemFulfillmentType = function (cartType) {
    var fulfillmentType = '';
    switch (cartType) {
        case 'BOPIC':
            fulfillmentType = 'Free Pickup';
            break;
        case 'SDD':
            fulfillmentType = 'Same Day Delivery';
            break;
        case 'ONLINE':
            fulfillmentType = 'Ship It';
            break;
        default:
            fulfillmentType = '';
            break;
    }
    return fulfillmentType;
};
//Method used to push all events data to adobedatalayer
export var addToCartAnalyticsEvent = function (fulfillmentType, quantity, type, from, carousel, onlineCartInfoCookie, productData, orderId) {
    var _prodViewEvent$produc, _window;
    // SP-16810 - Push AddToCart event to AdobeDataLayer and datalayer
    // @ts-ignore
    var prodViewEvent = getProductArray();
    var updartedProductData = productData ? productData : prodViewEvent === null || prodViewEvent === void 0 || (_prodViewEvent$produc = prodViewEvent.products) === null || _prodViewEvent$produc === void 0 ? void 0 : _prodViewEvent$produc[0];
    // @ts-ignore
    if ((_window = window) !== null && _window !== void 0 && _window.adobeDataLayer) {
        var _productsAdded;
        var productsAdded = __assign({}, updartedProductData);
        var prodName = (_productsAdded = productsAdded) === null || _productsAdded === void 0 ? void 0 : _productsAdded.itemName;
        if (from == undefined) {
            productsAdded.itemAddedAs = fulfillmentType === 'SDD' ? 'sdd' : '';
            productsAdded.itemFulfillmentType = getItemFulfillmentType(fulfillmentType);
            productsAdded.quantity = quantity;
            productsAdded.type = type;
            productsAdded.action = 'add';
        }
        else {
            productsAdded = from;
        }
        var modifiedProductsAdded = void 0;
        if (!onlineCartInfoCookie || onlineCartInfoCookie == '0') {
            if (from !== undefined) {
                var _productsAdded2;
                modifiedProductsAdded = (_productsAdded2 = productsAdded) === null || _productsAdded2 === void 0 ? void 0 : _productsAdded2.map(function (product) {
                    return __assign(__assign({}, product), { firstCart: true });
                });
            }
            else {
                modifiedProductsAdded = __assign(__assign({}, productsAdded), { firstCart: true });
            }
        }
        else {
            if (from !== undefined) {
                var _productsAdded3;
                modifiedProductsAdded = (_productsAdded3 = productsAdded) === null || _productsAdded3 === void 0 ? void 0 : _productsAdded3.map(function (product) {
                    return __assign({}, product);
                });
            }
            else {
                modifiedProductsAdded = __assign({}, productsAdded);
            }
        }
        var dataForDataLayer = {
            event: carousel == 'carousel' ? 'pdpProductClick' : 'AddToCart',
            page: {
                page: 'PDP',
                prodName: prodName
            },
            products: from !== undefined ? modifiedProductsAdded : [modifiedProductsAdded],
            user: prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.user
        };
        if (orderId) {
            dataForDataLayer['cartId'] = orderId;
        }
        sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    }
};
//Add to wishlist product data
export var addToWishListAnalyticsEvent = function (productData) {
    var _prodViewEvent$produc2, _window2;
    var prodViewEvent = getProductArray();
    var updartedProductData = productData ? productData : prodViewEvent === null || prodViewEvent === void 0 || (_prodViewEvent$produc2 = prodViewEvent.products) === null || _prodViewEvent$produc2 === void 0 ? void 0 : _prodViewEvent$produc2[0];
    // @ts-ignore
    if ((_window2 = window) !== null && _window2 !== void 0 && _window2.adobeDataLayer) {
        var productsAdded = __assign({}, updartedProductData);
        var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
        var dataForDataLayer = {
            event: 'AddToWishList',
            page: {
                page: 'PDP',
                prodName: prodName
            },
            products: productsAdded,
            user: prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.user
        };
        sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    }
};
//To send products data of sponsored products to adobedatalayer
export var SendSponsoredProductEvent = function (pData, ecid) {
    var prodName = pData === null || pData === void 0 ? void 0 : pData.ProductName;
    var productData = getProductArray();
    var products = [];
    pData.forEach(function (data) {
        var prodObj = getProductObj(data, 'Sponsored Products');
        prodObj['location'] = 'Sponsored Products Carousel';
        prodObj['ECID'] = ecid;
        prodObj['specialPrograms'] = 'sponsored';
        prodObj['productFindingMethod'] = 'Carousal';
        prodObj['productFindingSubMethod'] = 'Sponsored Products';
        products.push(prodObj);
    });
    var dataForDataLayer = {
        event: 'sponsoredProductImpression',
        page: {
            page: 'PDP',
            prodName: prodName
        },
        sponsoredProducts: products,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
//Method used to send
//Frequently bought carousel ATC data
//Sponsored Carousel ATC data
//Sponsored & Recommended carousel Product click data
export var ATCFreqBoughtItems = function (atcData, fullFillmentType, from, action, orderId) {
    var onlineCartInfoCookie = parseCookie(environmentConst.CART_INFO_COOKIE);
    var products = [];
    var prodObj;
    if (atcData.length > 0) {
        atcData.forEach(function (data) {
            var prodObj = getProductObj(data);
            prodObj['action'] = 'add', prodObj['location'] = 'Frequently Bought Carousel';
            prodObj['itemFulfillmentType'] = fullFillmentType;
            prodObj['quantity'] = 1;
            prodObj['itemAddedAs'] = undefined;
            prodObj['type'] = undefined;
            prodObj['productFindingMethod'] = 'Carousal';
            prodObj['productFindingSubMethod'] = 'Frequently Bought Together';
            products.push(prodObj);
        });
        if (products != undefined && (products === null || products === void 0 ? void 0 : products.length) > 0) {
            addToCartAnalyticsEvent('', '', '', products, undefined, onlineCartInfoCookie, orderId);
        }
    }
    else {
        prodObj = getProductObj(atcData, from);
        prodObj['action'] = action == 'addToCart' ? 'add' : 'productClick';
        prodObj['location'] = from + ' Carousel';
        if (from == 'Sponsored Products') {
            prodObj['ECID'] = atcData === null || atcData === void 0 ? void 0 : atcData.ECID;
            prodObj['specialPrograms'] = 'sponsored';
            prodObj['productFindingMethod'] = 'Carousal';
            prodObj['productFindingSubMethod'] = 'Sponsored Products';
        }
        else {
            prodObj['productFindingMethod'] = 'Carousal';
            prodObj['productFindingSubMethod'] = from;
        }
        if (action != 'addToCart') {
            var _prodObj, _prodObj2;
            sessionStorage.setItem(environmentConst.PRODUCT_FINDING_METHOD_SESSION_COOKIE, (_prodObj = prodObj) === null || _prodObj === void 0 ? void 0 : _prodObj.productFindingMethod);
            sessionStorage.setItem(environmentConst.PRODUCT_FINDING_SUB_METHOD_SESSION_COOKIE, (_prodObj2 = prodObj) === null || _prodObj2 === void 0 ? void 0 : _prodObj2.productFindingSubMethod);
        }
        products.push(prodObj);
        addToCartAnalyticsEvent('', '', '', products, action === 'addToCart' ? '' : 'carousel', onlineCartInfoCookie, orderId);
    }
};
//Method used to create a product object
var getProductObj = function (item, from) {
    var id;
    if (from == 'Sponsored Products') {
        var _item$DetailURL;
        var data = item === null || item === void 0 || (_item$DetailURL = item.DetailURL) === null || _item$DetailURL === void 0 ? void 0 : _item$DetailURL.split('/');
        id = data[data.length - 1];
    }
    var productObj = {
        articleId: (item === null || item === void 0 ? void 0 : item.ArticleId) || (item === null || item === void 0 ? void 0 : item.ID),
        availability: undefined,
        brand: undefined,
        breadcrumb: undefined,
        category: undefined,
        itemCatentryId: item === null || item === void 0 ? void 0 : item.ItemCatentryID,
        itemName: item === null || item === void 0 ? void 0 : item.ProductName,
        itemPartNumber: (item === null || item === void 0 ? void 0 : item.PartNumber) || undefined,
        orderType: undefined,
        position: 0,
        price: parseFloat(item === null || item === void 0 ? void 0 : item.displayPrice),
        productCatentryId: id || (item === null || item === void 0 ? void 0 : item.CatentryID),
        productImageURL: item === null || item === void 0 ? void 0 : item.ImageURL,
        productPageURL: window.location.href.replace(window.location.origin, ''),
        productPartNumber: undefined,
        rating: parseFloat(item === null || item === void 0 ? void 0 : item.RatingStars),
        salePrice: parseFloat(item === null || item === void 0 ? void 0 : item.displayPrice),
        advertiserId: (item === null || item === void 0 ? void 0 : item.advertiserId) || undefined,
        campaignId: (item === null || item === void 0 ? void 0 : item.campaignId) || undefined,
        partnerId: (item === null || item === void 0 ? void 0 : item.partnerId) || undefined,
        placementId: (item === null || item === void 0 ? void 0 : item.placementId) || undefined
    };
    return productObj;
};
//Method used to send product overview data
export var sendProdOVData = function () {
    var _productData$products;
    var productData = getProductArray();
    var productsAdded = __assign({}, (productData === null || productData === void 0 || (_productData$products = productData.products) === null || _productData$products === void 0 ? void 0 : _productData$products[0]));
    var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
    var dataForDataLayer = {
        event: 'productDescriptionInteraction',
        page: {
            page: 'PDP',
            prodName: prodName
        },
        products: productData.products,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
//send fulfillment data with updated price value
export var sendFulfillmentData = function (type, price) {
    var _productData$products2, _productData$products3;
    var fulfillmentType = type === 1 ? 'Bopic' : type === 2 ? 'SDD' : 'Shipping';
    var productData = getProductArray();
    var productsAdded = __assign({}, (productData === null || productData === void 0 || (_productData$products2 = productData.products) === null || _productData$products2 === void 0 ? void 0 : _productData$products2[0]));
    var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
    if ((productData === null || productData === void 0 || (_productData$products3 = productData.products) === null || _productData$products3 === void 0 ? void 0 : _productData$products3.length) > 0) {
        productData === null || productData === void 0 || productData.products.forEach(function (product) {
            product.price = price;
            product['itemFulfillmentType'] = fulfillmentType;
        });
    }
    var dataForDataLayer = {
        event: 'updateFulfillmentOptionInPDP',
        page: {
            page: 'PDP',
            prodName: prodName
        },
        products: productData === null || productData === void 0 ? void 0 : productData.products,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
//Method used to fetch the product array from existing 'prodView' event in datalayer
var getProductArray = function () {
    var _window3, _window4;
    // @ts-ignore
    if ((_window3 = window) !== null && _window3 !== void 0 && _window3.adobeDataLayer && ((_window4 = window) === null || _window4 === void 0 || (_window4 = _window4.adobeDataLayer) === null || _window4 === void 0 ? void 0 : _window4.length) > 0) {
        var _window5;
        // @ts-ignore
        var prodViewEvent = (_window5 = window) === null || _window5 === void 0 ? void 0 : _window5.adobeDataLayer.filter(function (e) { return e.event === 'prodView'; })[0];
        return prodViewEvent;
    }
};
export var sendBreadcrumbDetails = function (link) {
    var _window6;
    var prodViewEvent = getProductArray();
    // @ts-ignore
    if ((_window6 = window) !== null && _window6 !== void 0 && _window6.adobeDataLayer) {
        var _prodViewEvent$produc3;
        var productsAdded = __assign({}, (prodViewEvent === null || prodViewEvent === void 0 || (_prodViewEvent$produc3 = prodViewEvent.products) === null || _prodViewEvent$produc3 === void 0 ? void 0 : _prodViewEvent$produc3[0]));
        var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
        var dataForDataLayer = {
            event: 'pdpBreadcrumbClick',
            page: {
                page: 'PDP',
                prodName: prodName
            },
            user: prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.user,
            linkName: link,
            linkRegion: 'pdp breadcrumb'
        };
        sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    }
};
export var sendclipCouponData = function (coupon) {
    var _productData$products4, _coupon$offerRewards;
    var productData = getProductArray();
    var productsAdded = __assign({}, (productData === null || productData === void 0 || (_productData$products4 = productData.products) === null || _productData$products4 === void 0 ? void 0 : _productData$products4[0]));
    var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
    var couponDetails = {
        brandName: coupon === null || coupon === void 0 ? void 0 : coupon.brandName,
        category: coupon === null || coupon === void 0 || (_coupon$offerRewards = coupon.offerRewards) === null || _coupon$offerRewards === void 0 ? void 0 : _coupon$offerRewards.categoryName,
        offerCode: coupon === null || coupon === void 0 ? void 0 : coupon.offerCode,
        offerDescription: coupon === null || coupon === void 0 ? void 0 : coupon.offerDescription,
        pageName: 'PDP'
    };
    var dataForDataLayer = {
        event: 'buttonClickClipCoupon',
        page: {
            page: 'PDP',
            prodName: prodName
        },
        products: productData.products,
        coupon: couponDetails,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
export var sendMembersAlsoConsideredEvent = function (products) {
    var productData = getProductArray();
    var dataForDataLayer = {
        event: 'membersAlsoConsideredImpressions',
        page: {
            page: 'PDP'
        },
        products: products,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
