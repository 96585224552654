import styled from 'styled-components';
import { SharedStyledButton } from '../shared/SharedStyledButton'; // Assuming you are importing SharedStyledButton from another file
export var Button = styled(SharedStyledButton).withConfig({
    displayName: "AddToCartstyle__Button",
    componentId: "sc-tk1th-0"
})(["width:289px;@media (min-width:768px) and (max-width:992px){width:220px;}@media screen and (max-width:767px){width:100%;}"]);
export var AddCartStyling = styled.div.withConfig({
    displayName: "AddToCartstyle__AddCartStyling",
    componentId: "sc-tk1th-1"
})([".red-color-button{color:#fff;font-size:16px;font-weight:500;border:1px solid #c03 !important;background-color:#cc0033;border-radius:5px;padding-top:12px;padding-bottom:12px;height:40px;line-height:16px;font-weight:700;}.button-part{button{@media (max-width:482px){height:40px;}}}"]);
export var ButtonCarousel = styled(SharedStyledButton).withConfig({
    displayName: "AddToCartstyle__ButtonCarousel",
    componentId: "sc-tk1th-2"
})(["height:24px;width:100%;font-size:", ";padding:0 8px;font-weight:500;@media (max-width:482px){height:30px !important;}"], function (props) { return props.inCartBtn == 'ADD TO CART' || props.inCartBtn == 'IN CART' ? '12px' : '8px'; });
