import { parseClubPrices } from '../../../../utils/helper';
import { fulfillmentMethods, isNullOrUndefinedOrEmpty } from '../../../../utils/pdpUtil';
export var getClubLevelPrice = function (items, clubId, type) {
    var _a = items || {}, clubPrices = _a.clubPrices, clubSalePrices = _a.clubSalePrices, Price = _a.Price;
    var priceType = type !== null && type !== void 0 ? type : "Club";
    var clubSalePrice = parseClubPrices(priceType, clubSalePrices, clubId);
    var clubPrice = parseClubPrices(priceType, clubPrices, clubId);
    if (clubSalePrice) {
        return clubSalePrice;
    }
    else if (clubPrice) {
        return clubPrice;
    }
    else {
        return Price; // fallback logic
    }
};
export var getFulfillmentPrice = function (items, defaultFulfillment, headerClub, sddClub, pdpZipNotServiceable) {
    if (items === void 0) { items = {}; }
    if (defaultFulfillment === void 0) { defaultFulfillment = ""; }
    if (headerClub === void 0) { headerClub = ""; }
    if (sddClub === void 0) { sddClub = ""; }
    if (pdpZipNotServiceable === void 0) { pdpZipNotServiceable = false; }
    var _product$price;
    var _a = items || {}, _b = _a.availableInClub, availableInClub = _b === void 0 ? '' : _b, _c = _a.availableOnline, availableOnline = _c === void 0 ? '' : _c, _d = _a.displayPrice, displayPrice = _d === void 0 ? '' : _d, _e = _a.product, product = _e === void 0 ? {} : _e;
    var _f = fulfillmentMethods.pickup, pickup = _f === void 0 ? '' : _f, _g = fulfillmentMethods.delivery, delivery = _g === void 0 ? '' : _g, _h = fulfillmentMethods.shipping, shipping = _h === void 0 ? '' : _h;
    // getClubLevelPrice will check for price in salesClub and clubPrice
    if (isNullOrUndefinedOrEmpty(defaultFulfillment) || defaultFulfillment === pickup) {
        if (availableInClub === 'Y') {
            return getClubLevelPrice(items, headerClub);
        }
        else if (availableInClub === 'N') {
            return getClubLevelPrice(items, '', 'online');
        }
    }
    if (defaultFulfillment === delivery) {
        if (availableInClub === 'Y' && pdpZipNotServiceable != 'true') {
            return getClubLevelPrice(items, sddClub);
        }
        else if (availableInClub === 'N') {
            return getClubLevelPrice(items, '', 'online');
        }
    }
    if (defaultFulfillment === shipping) {
        if (availableOnline === 'Y') {
            return getClubLevelPrice(items, '', 'online');
        }
        else if (availableOnline === 'N') {
            return getClubLevelPrice(items, headerClub);
        }
    }
    return displayPrice ? displayPrice : (_product$price = product === null || product === void 0 ? void 0 : product.price) !== null && _product$price !== void 0 ? _product$price : 0;
};
