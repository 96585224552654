import { environmentConst } from '../../src/config/environments/constants';
import { parseCookie, parseObjectCookie, fetchUpdatedCookie } from './helper';
import { getKillSwitchForBopicProductLocations } from './storeConfHelper';
export var getProductDetails = function (pdpContext) {
    var _pdpContext$data;
    var productDetails = pdpContext === null || pdpContext === void 0 || (_pdpContext$data = pdpContext.data) === null || _pdpContext$data === void 0 ? void 0 : _pdpContext$data.productDetailsData;
    var item = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems[0];
    var bjsitem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems[0];
    var itemId = item === null || item === void 0 ? void 0 : item.itemID;
    var articleId = bjsitem === null || bjsitem === void 0 ? void 0 : bjsitem.articleId;
    var userData = productDetails === null || productDetails === void 0 ? void 0 : productDetails.userData;
    var descriptiveAttributes = productDetails === null || productDetails === void 0 ? void 0 : productDetails.descriptiveAttributes;
    var killSwitchMicroService = userData === null || userData === void 0 ? void 0 : userData.killSwitchMicroService;
    var partNumber = item === null || item === void 0 ? void 0 : item.partNumber;
    var hiddenAttr = productDetails === null || productDetails === void 0 ? void 0 : productDetails.hiddenAttr;
    var inventorySwitch = getInventorySwitch(killSwitchMicroService);
    return {
        item: item,
        itemId: itemId,
        articleId: articleId,
        inventorySwitch: inventorySwitch,
        descriptiveAttributes: descriptiveAttributes,
        partNumber: partNumber,
        hiddenAttr: hiddenAttr
    };
};
var getInventorySwitch = function (killSwitchMicroService) {
    if (killSwitchMicroService == 'ON') {
        return 'ON';
    }
    else {
        return 'OFF';
    }
};
export var getZipCode = function (cookies) {
    if (typeof window !== 'undefined') {
        var zipCode = cookies[environmentConst.CLUB_ZIP_CODE_COOKIE];
        if (zipCode) {
            return zipCode;
        }
        else {
            /**
             * Get info from localstorage
             */
            return getClubZipcode();
        }
    }
    return null;
};
export var getClubName = function (cookies) {
    if (typeof window !== 'undefined') {
        var clubName = cookies[environmentConst.CLUB_NAME_COOKIE];
        if (clubName) {
            return clubName;
        }
    }
    return null;
};
export var getClubFullName = function () {
    var clubDetails = getClubDetails();
    if (clubDetails) {
        var _clubDetails$Descript;
        var clubFullName = (clubDetails === null || clubDetails === void 0 || (_clubDetails$Descript = clubDetails.Description) === null || _clubDetails$Descript === void 0 || (_clubDetails$Descript = _clubDetails$Descript[0]) === null || _clubDetails$Descript === void 0 ? void 0 : _clubDetails$Descript.displayStoreName) + "," + (clubDetails === null || clubDetails === void 0 ? void 0 : clubDetails.stateOrProvinceName);
        return clubFullName !== null && clubFullName !== void 0 ? clubFullName : null;
    }
    return null;
};
/**
 * Use to get zipcode
 * from localstorage of club details
 * Can be used if zipcode is not defined in cookie
 * as alternate method to avoid any breaking functionality
 */
export var getClubZipcode = function () {
    var clubDetails = getClubDetails();
    if (clubDetails) {
        var clubZipCode = clubDetails === null || clubDetails === void 0 ? void 0 : clubDetails.postalCode;
        return clubZipCode !== null && clubZipCode !== void 0 ? clubZipCode : null;
    }
    return null;
};
/**
 *
 * @returns clubDetails info from localstorage
 */
export var getClubDetails = function () {
    if (typeof window !== 'undefined') {
        var clubDetails = localStorage.getItem(environmentConst.CLUB_DETAILS_STORAGE_KEY);
        if (clubDetails) {
            clubDetails = JSON.parse(clubDetails);
            return clubDetails;
        }
        else {
            return null;
        }
    }
    return null;
};
export var getClubInventoryLatLong = function (geoCodeResults) {
    var _geoCodeResults$geome, _geoCodeResults$geome2;
    var clubInventoryRequestObj = {};
    clubInventoryRequestObj['latitude'] = geoCodeResults === null || geoCodeResults === void 0 || (_geoCodeResults$geome = geoCodeResults.geometry) === null || _geoCodeResults$geome === void 0 || (_geoCodeResults$geome = _geoCodeResults$geome.location) === null || _geoCodeResults$geome === void 0 ? void 0 : _geoCodeResults$geome.lat();
    clubInventoryRequestObj['longitude'] = geoCodeResults === null || geoCodeResults === void 0 || (_geoCodeResults$geome2 = geoCodeResults.geometry) === null || _geoCodeResults$geome2 === void 0 || (_geoCodeResults$geome2 = _geoCodeResults$geome2.location) === null || _geoCodeResults$geome2 === void 0 ? void 0 : _geoCodeResults$geome2.lng();
    return clubInventoryRequestObj;
};
export var getBreadcrumbSchema = function (breadcrumb) {
    var position = 1;
    var breadcrumbList = [{
            '@type': 'ListItem',
            position: position++,
            item: {
                '@id': '',
                name: 'Home'
            }
        }];
    for (var product in breadcrumb) {
        if (typeof breadcrumb[product] === 'string' && breadcrumb[product].length > 0) {
            var _breadcrumb$product, _breadcrumb$product2;
            var productId = (_breadcrumb$product = breadcrumb[product]) === null || _breadcrumb$product === void 0 ? void 0 : _breadcrumb$product.split('|')[1];
            var productName = (_breadcrumb$product2 = breadcrumb[product]) === null || _breadcrumb$product2 === void 0 ? void 0 : _breadcrumb$product2.split('|')[0].trim();
            breadcrumbList.push({
                '@type': 'ListItem',
                position: position++,
                item: {
                    '@id': productId,
                    name: productName
                }
            });
        }
    }
    var breadcrumbSchema = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [breadcrumbList]
    };
    return breadcrumbSchema;
};
export var isNullOrUndefinedOrEmpty = function (input) {
    if (undefined !== input && null !== input && '' !== input && 'undefined' !== input) {
        return false;
    }
    else {
        return true;
    }
};
export var isNullOrUndefined = function (input) {
    if (undefined !== input && null !== input) {
        return false;
    }
    else {
        return true;
    }
};
export var addMonetateCustomVariables = function (cookies, kiboClubId) {
    var headerClubId = kiboClubId;
    if (!isNullOrUndefined(headerClubId)) {
        var headerClub = "Club" + headerClubId;
        var clubIdData = [{
                name: 'ClubID',
                value: headerClub
            }];
        // @ts-ignore
        monetateQ.push(['setCustomVariables', clubIdData]);
    }
    var isUserSignIn = cookies[environmentConst.USER_SIGNIN_RESPONSE_COOKIE];
    if (!isNullOrUndefinedOrEmpty(isUserSignIn)) {
        var memberId = localStorage.getItem('x_MembershipNumber') || (isUserSignIn === null || isUserSignIn === void 0 ? void 0 : isUserSignIn.x_MembershipNumber);
        if (!isNullOrUndefined(memberId)) {
            var memberData = [{
                    name: 'MemberID',
                    value: memberId
                }];
            // @ts-ignore
            monetateQ.push(['setCustomVariables', memberData]);
        }
    }
    return;
};
export var getfulfillmentName = function (fulfillmentType) {
    switch (fulfillmentType) {
        case 'BOPIC':
            return 'Pickup';
        case 'SDD':
            return 'Delivery';
        case 'ONLINE':
            return 'Shipping';
    }
};
export var isTrialMember = function () {
    var isMemberTrial = false;
    var userSignInResponse = fetchUpdatedCookie(environmentConst.USER_SIGNIN_RESPONSE_COOKIE);
    if (!isNullOrUndefinedOrEmpty(userSignInResponse)) {
        if (((userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.x_MemberRole) == 'Register non-member' || (userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.x_MemberRole) == 'non-member') && !isNullOrUndefined(userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.isValidTrialMember)) {
            isMemberTrial = true;
        }
    }
    return isMemberTrial;
};
export var checkFeatureFlag = function (segmentName, featureSegments) {
    var isUserSignIn = parseObjectCookie([environmentConst.USER_SIGNIN_RESPONSE_COOKIE]);
    if (featureSegments != null && featureSegments != undefined && !isNullOrUndefinedOrEmpty(isUserSignIn)) {
        if (featureSegments.toString().includes(segmentName)) {
            return true;
        }
    }
    return false;
};
export var getMemberFacingLocations = function (storeConf) {
    if (getKillSwitchForBopicProductLocations(storeConf) && checkFeatureFlag('DISPLAY_PRODUCT_LOCATION_PHASE1_20240124', parseCookie(environmentConst.B2C_MEMBER_SEGMENT))) {
        return true;
    }
    else if (!getKillSwitchForBopicProductLocations(storeConf)) {
        return true;
    }
    else {
        return false;
    }
};
export var constants = {
    carousalClubPrice: {
        club: 'Club',
        online: 'online'
    }
};
export var clubTypeIdRegex = /^([a-zA-Z]+)(\d*)$/;
export var fulfillmentMethods = {
    pickup: 'pickup',
    delivery: 'delivery',
    shipping: 'shipping'
};
export var fulfillmentStatusLabel = {
    unavailable: "Unavailable",
    soldout: "Sold Out",
    freeShipping: "Free Shipping",
    shipping: "Shipping"
};
