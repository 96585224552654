import styled from 'styled-components';
export var StyledText = styled.p.withConfig({
    displayName: "Textstyle__StyledText",
    componentId: "sc-of5nnk-0"
})(["color:", ";font-family:", ";font-weight:", ";font-size:", ";letter-spacing:", ";line-height:", ";padding:", ";margin:", ";max-height:", ";min-height:", ";overflow:", ";"], function (props) {
    var _props$theme, _props$styles;
    return props === null || props === void 0 || (_props$theme = props.theme) === null || _props$theme === void 0 || (_props$theme = _props$theme.palette) === null || _props$theme === void 0 ? void 0 : _props$theme[props === null || props === void 0 || (_props$styles = props.styles) === null || _props$styles === void 0 ? void 0 : _props$styles.textColor];
}, function (props) {
    var _props$theme2, _props$styles2;
    return props === null || props === void 0 || (_props$theme2 = props.theme) === null || _props$theme2 === void 0 || (_props$theme2 = _props$theme2.fontFamily) === null || _props$theme2 === void 0 ? void 0 : _props$theme2[props === null || props === void 0 || (_props$styles2 = props.styles) === null || _props$styles2 === void 0 ? void 0 : _props$styles2.fontFamily];
}, function (props) {
    var _props$theme3, _props$styles3;
    return props === null || props === void 0 || (_props$theme3 = props.theme) === null || _props$theme3 === void 0 || (_props$theme3 = _props$theme3.fontWeight) === null || _props$theme3 === void 0 ? void 0 : _props$theme3[props === null || props === void 0 || (_props$styles3 = props.styles) === null || _props$styles3 === void 0 ? void 0 : _props$styles3.fontWeight];
}, function (props) {
    var _props$styles4, _props$styles5, _props$theme4;
    return (_props$styles4 = props.styles) !== null && _props$styles4 !== void 0 && _props$styles4.fontSize ? (_props$styles5 = props.styles) === null || _props$styles5 === void 0 ? void 0 : _props$styles5.fontSize : props === null || props === void 0 || (_props$theme4 = props.theme) === null || _props$theme4 === void 0 || (_props$theme4 = _props$theme4.fontSize) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.normal;
}, function (props) {
    var _props$styles6, _props$styles7;
    return (_props$styles6 = props.styles) !== null && _props$styles6 !== void 0 && _props$styles6.letterSpacing ? (_props$styles7 = props.styles) === null || _props$styles7 === void 0 ? void 0 : _props$styles7.letterSpacing : '0';
}, function (props) {
    var _props$styles8, _props$styles9;
    return (_props$styles8 = props.styles) !== null && _props$styles8 !== void 0 && _props$styles8.lineHeight ? (_props$styles9 = props.styles) === null || _props$styles9 === void 0 ? void 0 : _props$styles9.lineHeight : 'normal';
}, function (props) {
    var _props$styles10, _props$styles11;
    return (_props$styles10 = props.styles) !== null && _props$styles10 !== void 0 && _props$styles10.padding ? (_props$styles11 = props.styles) === null || _props$styles11 === void 0 ? void 0 : _props$styles11.padding : 0;
}, function (props) {
    var _props$styles12, _props$styles13;
    return (_props$styles12 = props.styles) !== null && _props$styles12 !== void 0 && _props$styles12.margin ? (_props$styles13 = props.styles) === null || _props$styles13 === void 0 ? void 0 : _props$styles13.margin : 0;
}, function (props) {
    var _props$styles14, _props$styles15;
    return (_props$styles14 = props.styles) !== null && _props$styles14 !== void 0 && _props$styles14.maxHeight ? (_props$styles15 = props.styles) === null || _props$styles15 === void 0 ? void 0 : _props$styles15.maxHeight : 'none';
}, function (props) {
    var _props$styles16, _props$styles17;
    return (_props$styles16 = props.styles) !== null && _props$styles16 !== void 0 && _props$styles16.minHeight ? (_props$styles17 = props.styles) === null || _props$styles17 === void 0 ? void 0 : _props$styles17.minHeight : '0';
}, function (props) {
    var _props$styles18, _props$styles19;
    return (_props$styles18 = props.styles) !== null && _props$styles18 !== void 0 && _props$styles18.overflow ? (_props$styles19 = props.styles) === null || _props$styles19 === void 0 ? void 0 : _props$styles19.overflow : 'visible';
});
