import styled from 'styled-components';
export var ToastStyle = styled.span.withConfig({
    displayName: "Toaststyle__ToastStyle",
    componentId: "sc-kd4rpv-0"
})(["width:281px;height:48px;justify-content:space-between;color:", ";background-color:", ";position:", ";display:", ";align-items:", ";font-size:", ";font-weight:", ";padding:", ";box-shadow:", ";text-decoration:none;cursor:auto;@media screen and (min-width:768px){position:relative;transform:translateX(53%);}@media screen and (max-width:767px){width:358px;position:absolute;z-index:99;bottom:0%;margin:0 5px;left:50%;transform:translateX(-50%);}.toast-icon{width:24px;height:24px;border-radius:50%;background-color:#fff;border:0.6px solid rgba(75,133,16,1);display:flex;align-items:center;justify-content:center;margin-right:12px;img{width:12px;}}.toast-close{width:11px;height:11px;margin-left:12px;cursor:pointer;line-height:0;}"], function (props) {
    var _props$styles, _props$styles2;
    return (_props$styles = props.styles) !== null && _props$styles !== void 0 && _props$styles.color ? (_props$styles2 = props.styles) === null || _props$styles2 === void 0 ? void 0 : _props$styles2.color : '#fff';
}, function (props) {
    var _props$styles3, _props$styles4;
    return (_props$styles3 = props.styles) !== null && _props$styles3 !== void 0 && _props$styles3.backgroundColor ? (_props$styles4 = props.styles) === null || _props$styles4 === void 0 ? void 0 : _props$styles4.backgroundColor : '#555555';
}, function (props) {
    var _props$styles5, _props$styles6;
    return (_props$styles5 = props.styles) !== null && _props$styles5 !== void 0 && _props$styles5.position ? (_props$styles6 = props.styles) === null || _props$styles6 === void 0 ? void 0 : _props$styles6.position : 'static';
}, function (props) {
    var _props$styles7, _props$styles8;
    return (_props$styles7 = props.styles) !== null && _props$styles7 !== void 0 && _props$styles7.display ? (_props$styles8 = props.styles) === null || _props$styles8 === void 0 ? void 0 : _props$styles8.display : 'flex';
}, function (props) {
    var _props$styles9, _props$styles10;
    return (_props$styles9 = props.styles) !== null && _props$styles9 !== void 0 && _props$styles9.alignItems ? (_props$styles10 = props.styles) === null || _props$styles10 === void 0 ? void 0 : _props$styles10.alignItems : 'center';
}, function (props) {
    var _props$styles11, _props$styles12;
    return (_props$styles11 = props.styles) !== null && _props$styles11 !== void 0 && _props$styles11.fontSize ? (_props$styles12 = props.styles) === null || _props$styles12 === void 0 ? void 0 : _props$styles12.fontSize : '14px';
}, function (props) {
    var _props$styles13, _props$styles14;
    return (_props$styles13 = props.styles) !== null && _props$styles13 !== void 0 && _props$styles13.fontWeight ? (_props$styles14 = props.styles) === null || _props$styles14 === void 0 ? void 0 : _props$styles14.fontWeight : '500';
}, function (props) {
    var _props$styles15, _props$styles16;
    return (_props$styles15 = props.styles) !== null && _props$styles15 !== void 0 && _props$styles15.padding ? (_props$styles16 = props.styles) === null || _props$styles16 === void 0 ? void 0 : _props$styles16.padding : '10px 15px';
}, function (props) {
    var _props$styles17, _props$styles18;
    return (_props$styles17 = props.styles) !== null && _props$styles17 !== void 0 && _props$styles17.boxShadow ? (_props$styles18 = props.styles) === null || _props$styles18 === void 0 ? void 0 : _props$styles18.boxShadow : '0px 5px 10px -5px rgba(0, 0, 0, 0.5)';
});
