import React from 'react';
// import { PencilBannerData } from 'src/utils/types';
export var PdpContext = React.createContext({
    promotionData: [],
    data: {
        productDetailsData: null,
        colorVariant: null,
        currentGiftCardVariant: null,
        brCMSPDPData: null,
        brUrl: {
            BR_BASE_URL: null
        }
    },
    loading: false,
    productData: null,
    storeConf: null,
    helpers: []
});
