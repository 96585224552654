var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Cookies } from 'react-cookie';
export var clearCookie = function (cookieName) {
    if (document) {
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
};
export var getProductIdFromPathname = function (pathname) {
    var pathnameArray = pathname === null || pathname === void 0 ? void 0 : pathname.split('/');
    if (pathnameArray[4]) {
        return null;
    }
    if (pathnameArray[3]) {
        var productId = pathnameArray[3];
        return productId;
    }
    return null;
};
export var getProductNameFromPathname = function (pathname) {
    var pathnameArray = pathname === null || pathname === void 0 ? void 0 : pathname.split('/');
    if (pathnameArray[2]) {
        var productName = pathnameArray[2];
        return productName;
    }
    return null;
};
export var removeSpecialCharFromProdName = function (prodName) {
    if (prodName) {
        var productName = prodName.trim().replace(/ /g, '-').toLowerCase();
        productName = productName.trim().replace(/[^a-zA-Z_0-9-_ ]/g, '');
        return productName;
    }
    return null;
};
export var getEnvProp = function (propName) {
    if (typeof window === 'undefined') {
        return process.env[propName];
    }
    else {
        //@ts-ignore
        return window['env'] && window.env[propName];
    }
};
export function dateFormat(inputDate, format) {
    //parse the input date
    var date = new Date(inputDate);
    //extract the parts of the date
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    //replace the month
    format = format.replace('MM', month.toString().padStart(2, '0'));
    //replace the year
    if (format.indexOf('yyyy') > -1) {
        format = format.replace('yyyy', year.toString());
    }
    else if (format.indexOf('yy') > -1) {
        format = format.replace('yy', year.toString().substr(2, 2));
    }
    //replace the day
    format = format.replace('dd', day.toString().padStart(2, '0'));
    return format;
}
export function parseCookie(name) {
    if (typeof window !== 'undefined') {
        if (document.cookie) {
            var _document$cookie;
            var cookieValue = (_document$cookie = document.cookie) === null || _document$cookie === void 0 || (_document$cookie = _document$cookie.split('; ').find(function (row) { return row.startsWith(name); })) === null || _document$cookie === void 0 ? void 0 : _document$cookie.split('=')[1];
            return cookieValue;
        }
    }
}
export var parseObjectCookie = function (key) {
    if (typeof window !== 'undefined') {
        var _document$cookie2;
        var cookieObj = (_document$cookie2 = document.cookie) === null || _document$cookie2 === void 0 ? void 0 : _document$cookie2.split(';').map(function (v) { return v === null || v === void 0 ? void 0 : v.split('='); }).reduce(function (acc, v) {
            var _v$, _v$2;
            acc[decodeURIComponent(v === null || v === void 0 || (_v$ = v[0]) === null || _v$ === void 0 ? void 0 : _v$.trim())] = decodeURIComponent(v === null || v === void 0 || (_v$2 = v[1]) === null || _v$2 === void 0 ? void 0 : _v$2.trim());
            return acc;
        }, {});
        if (cookieObj && cookieObj[key]) {
            return JSON.parse(cookieObj[key]);
        }
    }
};
export var checkRestrictedZipcodes = function (shippingRestrictionValueArray) {
    var shippingRestricted = false;
    if (shippingRestrictionValueArray) {
        if (shippingRestrictionValueArray.length !== 1) {
            var _shippingRestrictionV, _shippingRestrictionV2, _shippingRestrictionV3, _shippingRestrictionV4;
            var delivery = ((_shippingRestrictionV = shippingRestrictionValueArray[0]) === null || _shippingRestrictionV === void 0 ? void 0 : _shippingRestrictionV.servicetype) === 'D' ? (_shippingRestrictionV2 = shippingRestrictionValueArray[0]) === null || _shippingRestrictionV2 === void 0 ? void 0 : _shippingRestrictionV2.status : '';
            var install = ((_shippingRestrictionV3 = shippingRestrictionValueArray[1]) === null || _shippingRestrictionV3 === void 0 ? void 0 : _shippingRestrictionV3.servicetype) === 'I' ? (_shippingRestrictionV4 = shippingRestrictionValueArray[1]) === null || _shippingRestrictionV4 === void 0 ? void 0 : _shippingRestrictionV4.status : '';
            shippingRestricted = delivery === 'true' && install === 'true' || delivery === 'true' && install === 'false' ? true : false;
        }
        else {
            if (shippingRestrictionValueArray[0].status === 'true' && shippingRestrictionValueArray[0].servicetype === 'D') {
                shippingRestricted = true;
            }
        }
    }
    return shippingRestricted;
};
export var imageExists = function (image_url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status < 400;
};
export var fetchMapPrice = function (hiddenAttr) {
    return hiddenAttr === null || hiddenAttr === void 0 ? void 0 : hiddenAttr.find(function (item) {
        var _item$attributeValueD;
        return item.name == 'ShowPriceCartPostLogin' && ((_item$attributeValueD = item.attributeValueDataBeans) === null || _item$attributeValueD === void 0 || (_item$attributeValueD = _item$attributeValueD[0]) === null || _item$attributeValueD === void 0 ? void 0 : _item$attributeValueD.value) == 'Y';
    });
};
export var fetchAlcoholItem = function (hiddenAttr) {
    var _alcoholItem$;
    var alcoholItem = hiddenAttr === null || hiddenAttr === void 0 ? void 0 : hiddenAttr.filter(function (item) { return item.name == 'alcoholItem'; });
    return ((_alcoholItem$ = alcoholItem[0]) === null || _alcoholItem$ === void 0 || (_alcoholItem$ = _alcoholItem$.attributeValueDataBeans) === null || _alcoholItem$ === void 0 || (_alcoholItem$ = _alcoholItem$[0]) === null || _alcoholItem$ === void 0 ? void 0 : _alcoholItem$.value) == 'Y';
};
export var getDefaultFulFillmentType = function (queryParam) {
    var fulFillmentType = null;
    var listOfQueryParams = queryParam === null || queryParam === void 0 ? void 0 : queryParam.split('&');
    listOfQueryParams === null || listOfQueryParams === void 0 || listOfQueryParams.forEach(function (param) {
        if (param && param.includes('fulfillment')) {
            var _param$split;
            fulFillmentType = param === null || param === void 0 || (_param$split = param.split('=')) === null || _param$split === void 0 ? void 0 : _param$split[1];
        }
    });
    return fulFillmentType;
};
export var onClickTracker = function (item) {
    var oImg = document.createElement('img');
    oImg.src = (item === null || item === void 0 ? void 0 : item.click_tracker) || (item === null || item === void 0 ? void 0 : item.ClickTracker);
    oImg.height = 1;
    oImg.width = 1;
    document.body.appendChild(oImg);
};
export var fetchUpdatedCookie = function (cookiename) {
    var cookies = new Cookies();
    return cookies.get(cookiename);
};
export var onImpressionTracker = function (item) {
    var oImg = document.createElement('img');
    oImg.src = (item === null || item === void 0 ? void 0 : item.impression_tracker) || (item === null || item === void 0 ? void 0 : item.ImpressionTracker);
    oImg.height = 1;
    oImg.width = 1;
    document.body.appendChild(oImg);
};
export var isInViewport = function (element) {
    var _window, _document, _window2, _document2;
    var rect = element === null || element === void 0 ? void 0 : element.getBoundingClientRect();
    return (rect === null || rect === void 0 ? void 0 : rect.top) >= 0 && (rect === null || rect === void 0 ? void 0 : rect.left) >= 0 && (rect === null || rect === void 0 ? void 0 : rect.bottom) <= (((_window = window) === null || _window === void 0 ? void 0 : _window.innerHeight) || ((_document = document) === null || _document === void 0 || (_document = _document.documentElement) === null || _document === void 0 ? void 0 : _document.clientHeight)) && (rect === null || rect === void 0 ? void 0 : rect.right) <= (((_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.innerWidth) || ((_document2 = document) === null || _document2 === void 0 || (_document2 = _document2.documentElement) === null || _document2 === void 0 ? void 0 : _document2.clientWidth));
};
export var useMediaQuery = function (query) {
    var getMatches = function (query) {
        // Prevents SSR issues
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches;
        }
        return false;
    };
};
export var isBrowser = function () { return typeof window !== 'undefined'; };
export function navigateTo(url) {
    if (typeof window !== 'undefined') {
        window.location.href = url;
    }
}
export var getPriceClassName = function (section) {
    if (section === void 0) { section = ''; }
    var PRODUCT_IDS = {
        ARTICLE_1: '3000000000005067747',
        // Article 1
        ARTICLE_2: '3000000000004994811',
        // Article 2
        ARTICLE_3: '3000000000005107747' // Article 3
    };
    var ARTICLE_1 = PRODUCT_IDS.ARTICLE_1, ARTICLE_2 = PRODUCT_IDS.ARTICLE_2, ARTICLE_3 = PRODUCT_IDS.ARTICLE_3;
    if (isBrowser()) {
        var productId = getProductIdFromPathname(window.location.pathname);
        switch (section) {
            case 'fbt-section':
                switch (productId) {
                    case ARTICLE_1:
                    case ARTICLE_2:
                        return 'fbt-price';
                    default:
                        return 'item-price';
                }
            case 'main-section':
                switch (productId) {
                    case ARTICLE_3:
                        return 'product-price';
                    default:
                        return 'normal-price';
                }
            default:
                switch (section) {
                    case 'main':
                        return 'normal-price';
                    // Default for main section is normal-price
                    default:
                        return 'item-price';
                    // Default for FBT and carousel sections
                }
        }
    }
    return '';
};
export function isMapItem(productDetails) {
    var _productDetails$hidde;
    var isMapItem = false;
    productDetails === null || productDetails === void 0 || (_productDetails$hidde = productDetails.hiddenAttr) === null || _productDetails$hidde === void 0 || _productDetails$hidde.forEach(function (d) {
        var _d$attributeValueData;
        if ((d === null || d === void 0 ? void 0 : d.name) === 'MAP_Type' || (d === null || d === void 0 ? void 0 : d.name) == 'ShowPriceCartPostLogin' && (d === null || d === void 0 || (_d$attributeValueData = d.attributeValueDataBeans) === null || _d$attributeValueData === void 0 || (_d$attributeValueData = _d$attributeValueData[0]) === null || _d$attributeValueData === void 0 ? void 0 : _d$attributeValueData.value) == 'Y') {
            isMapItem = true;
        }
    });
    return isMapItem;
}
export function getStoreConfig() {
    if (typeof window !== 'undefined') {
        //@ts-ignore
        var storeConf = window.storeConf;
        return storeConf;
    }
}
export function extractClubPriceType(input) {
    var clubTypeIdRegex = /^([a-zA-Z]+)(\d*)$/;
    var _a = __read(input === null || input === void 0 ? void 0 : input.split('_'), 2), club = _a[0], price = _a[1];
    var ClubIdMatch = club === null || club === void 0 ? void 0 : club.match(clubTypeIdRegex);
    if (ClubIdMatch && price) {
        var type = ClubIdMatch === null || ClubIdMatch === void 0 ? void 0 : ClubIdMatch[1];
        var id = ClubIdMatch === null || ClubIdMatch === void 0 ? void 0 : ClubIdMatch[2];
        return {
            type: type,
            id: id,
            price: price
        };
    }
    else {
        return {};
    }
}
export function parseClubPrices(name, clubPrices, clubId) {
    var e_1, _a;
    var clubPrice = clubPrices === null || clubPrices === void 0 ? void 0 : clubPrices.split(',');
    if (clubPrice) {
        try {
            for (var clubPrice_1 = __values(clubPrice), clubPrice_1_1 = clubPrice_1.next(); !clubPrice_1_1.done; clubPrice_1_1 = clubPrice_1.next()) {
                var clubWithPrice = clubPrice_1_1.value;
                var _b = extractClubPriceType(clubWithPrice), _c = _b.type, type = _c === void 0 ? '' : _c, _d = _b.id, id = _d === void 0 ? '' : _d, _e = _b.price, price = _e === void 0 ? '' : _e;
                if (name === type && price && (name === 'online' || id === clubId)) {
                    return price;
                }
                continue;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (clubPrice_1_1 && !clubPrice_1_1.done && (_a = clubPrice_1.return)) _a.call(clubPrice_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return null;
}
export var constants = {
    carousalScheme: {
        monetate: "Monetate/Kibo"
    }
};
export var isEBTFsaHSAEligible = function (productDetails, headerClubId) {
    var _productDetails$itemA;
    var headerClubEBT = productDetails === null || productDetails === void 0 || (_productDetails$itemA = productDetails.itemAvailDetailsELIG) === null || _productDetails$itemA === void 0 || (_productDetails$itemA = _productDetails$itemA.Eligibility) === null || _productDetails$itemA === void 0 || (_productDetails$itemA = _productDetails$itemA[0]) === null || _productDetails$itemA === void 0 || (_productDetails$itemA = _productDetails$itemA.attributes) === null || _productDetails$itemA === void 0 || (_productDetails$itemA = _productDetails$itemA.clubEBT) === null || _productDetails$itemA === void 0 ? void 0 : _productDetails$itemA['Y'];
    var isEBTEligible = headerClubEBT === null || headerClubEBT === void 0 ? void 0 : headerClubEBT.includes(headerClubId);
    var hiddenAttr = (productDetails || {}).hiddenAttr;
    var fsaHsaEligible = 'N';
    if (hiddenAttr != null && hiddenAttr != undefined) {
        hiddenAttr === null || hiddenAttr === void 0 || hiddenAttr.map(function (attr) {
            var _attr$attributeValueD;
            var attrName = attr === null || attr === void 0 || (_attr$attributeValueD = attr.attributeValueDataBeans) === null || _attr$attributeValueD === void 0 || (_attr$attributeValueD = _attr$attributeValueD[0]) === null || _attr$attributeValueD === void 0 ? void 0 : _attr$attributeValueD.name;
            if ((attr === null || attr === void 0 ? void 0 : attr.name) === 'FSA_Eligible' || attrName === 'FSA_Eligible') {
                var _attr$attributeValueD2;
                fsaHsaEligible = attr === null || attr === void 0 || (_attr$attributeValueD2 = attr.attributeValueDataBeans) === null || _attr$attributeValueD2 === void 0 || (_attr$attributeValueD2 = _attr$attributeValueD2[0]) === null || _attr$attributeValueD2 === void 0 ? void 0 : _attr$attributeValueD2.value;
            }
        });
    }
    var displayStr = '';
    if (isEBTEligible && fsaHsaEligible === 'Y') {
        displayStr = 'SNAP EBT & FSA/HSA Eligible';
    }
    else if (isEBTEligible) {
        displayStr = 'SNAP EBT Eligible';
    }
    else if (fsaHsaEligible === 'Y') {
        displayStr = 'FSA/HSA Eligible';
    }
    return displayStr;
};
export var getProductItemNumber = function (productDetails) {
    var productItemNumber = '';
    if (productDetails !== null && productDetails !== void 0 && productDetails.definingAttributes && (productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.length) > 0) {
        var currentProduct = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (p) { return p.itemID === productDetails.itemPrices.defaultItemId; });
        if (currentProduct) {
            productItemNumber = currentProduct.articleId;
        }
    }
    else {
        productItemNumber = (productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems[0].articleId) || '';
    }
    return productItemNumber;
};
export var errorImage = function (e) {
    //@ts-ignore
    e.currentTarget.onError = null;
    //@ts-ignore
    e.currentTarget.src = 'https://bjs.scene7.com/is/image/bjs/no_image_found?$bjs-Initial400$';
    //@ts-ignore
    e.currentTarget.alt = 'Not Found';
};
export var scrollToElement = function (targetId) {
    var element = document.getElementById(targetId);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }
};
export var getBRPathName = function (category) {
    return "/product/" + category;
};
