import styled from 'styled-components';
export var ProtectionPlusUiTwo = styled.div.withConfig({
    displayName: "ProtectionPlusDomNewstyle__ProtectionPlusUiTwo",
    componentId: "sc-1n91ujd-0"
})([".cls-warranty-main{@media (max-width:480px){display:flex;}}.cls-protection{opacity:1;font-family:'Roboto',sans-serif;color:#303030;font-size:16px;padding-left:8px;font-weight:500;}.escl-wrap{display:flex;padding-left:8px;position:relative;margin-left:20px;i{color:#cc0033;transform:rotate(180deg);margin-left:7px;}}.cls-font{font-size:10px;}.clsWarTitle{max-width:100%;margin-left:0px;@media (max-width:576px){max-width:100% !important;margin-left:0px;}.col-5{padding-right:0px !important;padding-left:0px;max-width:38%;}.item-infoName{font-size:14px;font-style:normal;font-weight:500;line-height:17px;letter-spacing:0.5px;padding-bottom:12px;float:right;padding-top:12px;}}.protectionplus-wrap{@media (max-width:480px){display:flex;}}"]);
export var ProtectionPlus = styled.div.withConfig({
    displayName: "ProtectionPlusDomNewstyle__ProtectionPlus",
    componentId: "sc-1n91ujd-1"
})(["display:flex;align-items:center;justify-content:space-between;width:100%;height:100%;.inner-div{width:100%;padding-left:4px;gap:24px;display:flex;align-items:center;}.checkbox-warranty-check{height:26px;width:26px;border-radius:5px;border:1px solid #cc0033;appearance:none;outline:none;cursor:pointer;position:relative;}.checkbox-warranty-check:checked{background-color:#cc0033;}.checkbox-warranty-check:checked::after{content:\"\u2713\";position:absolute;cursor:pointer;color:#ffffff;top:50%;left:50%;font-size:20px;transform:translate(-50%,-50%);display:flex;align-items:center;justify-content:center;}.details{width:100%;gap:8px;display:flex;flex-wrap:wrap;}.inner-offer{width:auto;font-family:Roboto;font-size:14px;font-weight:500;line-height:16.41px;text-align:left;}.what-covered{width:83px;font-family:Roboto;font-size:12px;font-weight:400;line-height:14.06px;text-align:left;text-decoration:underline;color:#000000;cursor:pointer;}.main-details{width:100%;font-family:Roboto;font-size:16px;font-weight:500;line-height:18.75px;text-align:left;color:#303030;}.special-details{width:100%;gap:8px;display:flex;flex-wrap:wrap;@media (max-width:480px){gap:4px;}}.item-image{width:51px;height:58px;gap:3px;}.details{flex:1;}"]);
export var StyledImage = styled.img.withConfig({
    displayName: "ProtectionPlusDomNewstyle__StyledImage",
    componentId: "sc-1n91ujd-2"
})(["max-width:", ";padding-top:", ";@media (max-width:480px){max-width:", ";}"], function (props) { return props.ui === 1 ? '37px' : '75px'; }, function (props) { return props.ui === 1 ? 0 : '0px'; }, function (props) { return props.ui === 1 ? '43px' : '75px'; });
