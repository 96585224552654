import styled from 'styled-components';
// Styled components for static structure
export var ReturnPolicyParagraph = styled.p.withConfig({
    displayName: "ShippingReturnstyle__ReturnPolicyParagraph",
    componentId: "sc-rquouz-0"
})(["font-size:14px;color:#000000;"]);
export var ReturnGuidelinesTitle = styled.h3.withConfig({
    displayName: "ShippingReturnstyle__ReturnGuidelinesTitle",
    componentId: "sc-rquouz-1"
})(["font-size:14px;font-family:'Roboto';line-height:19px;color:#000000;font-weight:bolder;padding:0 13px;"]);
export var ReturnPolicyLink = styled.a.withConfig({
    displayName: "ShippingReturnstyle__ReturnPolicyLink",
    componentId: "sc-rquouz-2"
})(["color:#0000ee;text-decoration:underline;&:hover{color:#0000ee;text-decoration:underline;}"]);
export var ReturnGuidelinesList = styled.ul.withConfig({
    displayName: "ShippingReturnstyle__ReturnGuidelinesList",
    componentId: "sc-rquouz-3"
})(["margin-top:12px;margin-left:20px;padding-left:20px;list-style-type:disc;"]);
export var ListItem = styled.li.withConfig({
    displayName: "ShippingReturnstyle__ListItem",
    componentId: "sc-rquouz-4"
})(["font-size:14px;color:#000000;margin-bottom:5px;"]);
