export var getProductArticleId = function (productDetails) {
    var productItemNumber = '';
    if (productDetails !== null && productDetails !== void 0 && productDetails.definingAttributes && (productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.length) > 0) {
        var _productDetails$entit;
        var currentProduct = productDetails === null || productDetails === void 0 || (_productDetails$entit = productDetails.entitledItems) === null || _productDetails$entit === void 0 ? void 0 : _productDetails$entit.find(function (p) { return p.itemID === productDetails.itemPrices.defaultItemId; });
        if (currentProduct) {
            productItemNumber = currentProduct.articleId;
        }
    }
    else {
        var _productDetails$entit2;
        productItemNumber = (productDetails === null || productDetails === void 0 || (_productDetails$entit2 = productDetails.entitledItems) === null || _productDetails$entit2 === void 0 || (_productDetails$entit2 = _productDetails$entit2[0]) === null || _productDetails$entit2 === void 0 ? void 0 : _productDetails$entit2.articleId) || '';
    }
    return productItemNumber;
};
